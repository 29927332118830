@media only screen and (max-width: 500px){
  .main_img {
    img {
    	width: 100%!important;
    }
  }
  .mobile-footer h5 {
  	font-size: 14px;
  }
}
