@font-face {
  font-family: HelveticaBold;
  src: url(../font/HelveticaBold.otf);
 }
@font-face {
  font-family: Helvetic;
  src: url(../font/Helvetica.otf);
 }
@font-face {
  font-family: HelveticRegular;
  src: url(../font/helvetica_regular.ttf);
 }
@font-face {
  font-family: HelveticBold;
  src: url(../font/Helvetica-Bold.ttf);
 }

@font-face {
  font-family: HelveticNeue;
  src: url(../font/HelveticaNeue.ttf);
 }
// @font-face {
//  font-family: HelveticNeueBoldOtp;
//  src: url(../font/HelveticaNeue-Bold.otp);
// }
//
@font-face {
 font-family: HelveticNeueMedium;
 src: url(../font/HelveticaNeue-Medium.ttf);
}
@font-face {
 font-family: HelveticNeueBold;
 src: url(../font/HelveticaNeue-Bold.ttf);
}
@font-face {
  font-family: HelveticNeueRegular;
  src: url(../font/HelveticaNeueRegular.ttf);
 }