@media only screen and (min-width: 320px){
  body{
    font-size: 14px;
  }
  h2{
    font-size: 18px;
  }
  .wrapper-nav{
    font-family: HelveticNeueMedium;
    padding-left: 0;
    padding-right: 0;
  }
  .mobile-nabar span{
    vertical-align: -webkit-baseline-middle;
  }
  .nav-item a{
    text-align: center;
  }
  .navbar-light .navbar-nav .nav-link:focus, .navbar-light .navbar-nav .nav-link:hover{
    color: $active-color;
  }
  .mobile-nav-link{
    padding: 1.5rem 1rem;
  }
  .bg-light{
    background-color: #fff !important;
  }
  .navbar-light .navbar-toggler{
    border-color: #fff;
  }
  .navbar-light .navbar-nav .nav-link{
    color: #474747;
  }
  .mobile-navbar-toggler:focus{
    outline: 0px auto -webkit-focus-ring-color !important;
  }
  .nav-link-faq{
    display: none;
  }
  .about{
    padding-top: 5%;
    padding-bottom: 5%;
    h2{
    font-family: HelveticNeueBold;
    padding-bottom: 20px;
    line-height: 1.4;
    }
    p{
      font-family: HelveticNeueRegular;
      line-height: 1.67;
    }
  }
  .wrapper-record{
    background-color: rgba(216, 216, 216, .18);
    padding-top: 8%;
    p{
      font-family: HelveticNeueRegular;
      line-height: 1.67;
    }
  }

  .record{
    span{
      float: left;
      padding-top: 12%;
      padding-bottom: 5%;
      text-align: center;
      width: 100%;
      a{
        background-color: #533895;
        border-radius: 25px;
        color: #fff;
        font-family: Helvetic;
        height: 44px;
        width: 50%;
        i{
          color: #fff;
        }
        &:hover{
          box-shadow: 0 9px 22px 0 rgba(83,56,149,0.40);
        }
      }
    }
    h5{
      color: #fff;
      font-family: HelveticNeueRegular;
      font-weight: 300;
      text-transform: uppercase;
      padding-bottom: 10px;
    }
  }
  .record-title{
    padding-top: 10%;
  }
  .record-list{
    padding-left: 5%;
    font-family: HelveticNeueRegular;
    line-height: 1.9;
    list-style: disc outside;
    li{
      font-family: HelveticNeueRegular;
      color: #fff;
    }
  }
  .wrapper-mobile-contact{
    padding-top: 8%;
    padding-bottom: 8%;
    border-bottom: solid 1px rgba(151,151,151, .33);
  }
  .mobile-contac {
    h2{
      font-family: HelveticNeueBold;
      padding-bottom: 1%;
      text-align: center;
    }
    p{
      font-family: HelveticNeueRegular;
      line-height: 2;
      text-align: center;
      padding-bottom: 7%;
    }
    span{
      display: block;
      padding-top: 3%;
      padding-bottom: 3%;
      text-align: center;
      width: 100%;
    }
    label{
      padding-bottom: 5px;
    }
  }
  .tablet-form, .button, input, optgroup, select, textarea, span{
    font-family: HelveticNeueRegular;
  }
  .form-group label{
    font-family: HelveticNeueRegular;
  }
  .mobile-form-control {
    &:focus{
      border-color: $active-color;
      box-shadow:0 0 0 0rem rgba(0,0,0,.25) !important;
    }
  }
  .mobile-btn-send{
    color: white;
    box-sizing: border-box;
    width: 136px;
    height: 42px;
    border-radius: 7px;
    background-color: #474747;
    font-family: Helvetica;
    font-size: 16px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: -0.5px;
    text-align: center;
    padding: 5px 15px;
    &:hover{
      box-shadow: 0 14px 20px 0 rgba(0, 0, 0, 0.3);
    }
  }
  .wrapper-footer{
    padding-left: 0;
    padding-right: 0;
  }
  .mobile-footer{
    display: block;
    padding-left: 15px;
    padding-right: 15px;
    a{
      font-family: HelveticNeueRegular;
      padding: 3% 4%;
      text-decoration: none;
      text-align: center;
      &:hover span {
        color: $active-color;
      }
    }
    i{
      float: right;
    }
    h5{
      font-family: HelveticNeueRegular;
      float: left;
      color: #AEAEAE;
      padding: 2% 2%;
    }
  }
  .footer-faq{
    display: none;
  }

}
.api_ref {
  text-align: center;
  margin-bottom: 25px;
  img {
    width: 161px;
    height: 60px;
    border-radius: 8px;
    &:hover {
      box-shadow: 0 14px 20px 0 rgba(0, 0, 0, 0.31);
    }
  }
}
.record-list-img {
  width: 80%;
}