@media only screen and (max-width: 319px){
  body{
    font-size: 12px;
  }
  h2{
    font-size: 18px;
  }
  .wrapper-nav{
    padding-left: 0;
    padding-right: 0;
  }
  .mobile-nabar span{
    vertical-align: -webkit-baseline-middle;
  }
  .nav-item a{
    text-align: center;
    &:hover{
      color: red;
    }
  }
  .navbar-light .navbar-nav .nav-link:focus, .navbar-light .navbar-nav .nav-link:hover{
    color: #E6982E;
  }
  .mobile-nav-link{
    padding: 1.5rem 1rem;
  }
  .bg-light{
    background-color: #fff !important;
  }
  .navbar-light .navbar-toggler{
    border-color: #fff;
  }
  .navbar-light .navbar-nav .nav-link{
    color: #474747;
  }
  .mobile-navbar-toggler:focus{
    outline:0px auto -webkit-focus-ring-color !important;
  }
  .about{
    padding-top: 10%;
    padding-bottom: 10%;
    h2{
    font-family: HelveticaBold;
    padding-bottom: 20px;
    }
    p{
      font-family: HelveticRegular;
      line-height: 2;
    }
  }
  .wrapper-record{
    background-color: #E6982E;
    padding-top: 10%;
    margin-bottom: 20%;
  }
  .record-img-third{
    display: none;
  }
  .record-img-first{
    background: rgba(52,63,75,0.18);
    box-shadow: 0 0 0 1px rgba(52,63,75,0.02), 0 19px 60px 0 rgba(89,109,130,0.08), 0 10px 30px 0 rgba(58,74,93,0.23);
    left: 13%;
    position: absolute;
    top:0;
    width: 31%;
    z-index: 5;
  }
  .record-img-second{
      box-shadow: 7px 10px 20px 0 rgba(0,0,0,0.19);
      top:8px;
      right: 26%;
      position: absolute;
      width: 32%;
      z-index: 1;
  }
  .record{
    span{
      float: left;
      padding-top: 12%;
      padding-bottom: 12%;
      text-align: center;
      width: 100%;
      a{
        background-color: #533895;
        border-radius: 25px;
        color: #fff;
        font-family: Helvetic;
        height: 44px;
        width: 59%;
        i{
          color: #fff;
        }
        &:hover{
          box-shadow: 0 9px 22px 0 rgba(83,56,149,0.40);
        }
      }
    }
  }
  .record-list{
    padding-left: 10%;
    font-family: HelveticNeue;
    line-height: 1.9;
    list-style: disc outside;
    li{
      color: #FFFFFF;
    }
  }
  .wrapper-mobile-contact{
    background-color: #FAFAFA;
    padding-top: 10%;
    padding-bottom: 10%;
  }
  .mobile-contac {
    h2{
      font-family: HelveticNeueBold;
      padding-bottom: 6%;
    }
    p{
      padding-bottom: 11%;
    }
    span{
      display: block;
      padding-top: 9%;
      padding-bottom: 4%;
      text-align: center;
      width: 100%;
    }
    label{
      padding-bottom: 3%;
    }
  }
  .mobile-form-control {
    &:focus{
      border-color: #E6982E;
      box-shadow:0 0 0 0rem rgba(0,0,0,.25) !important;
    }
  }
  .mobile-btn-send{
    background-color: rgba(98%, 98%, 98%, 0.1);
    border: 1px solid  #533895;
    border-radius: 23px;
    color: #533895;
    font-family: Helvetic;
    height: 44px;
    width: 58%;
    &:hover{
      box-shadow: 0 9px 22px 0 rgba(83,56,149,0.40);
    }
  }
  .wrapper-footer{
    padding-left: 0;
    padding-right: 0;
  }
  .mobile-footer{
    display: block;
    a{
      float: left;
      font-family: HelveticNeue;
      border-bottom: 1px solid #979797;
      padding: 6% 4%;
      text-decoration: none;
      width: 100%;
    }
    i{
      float: right;
    }
    h5{
      font-family: HelveticNeue;
      float: left;
      color: #AEAEAE;
      padding: 5% 4%;
    }
  }
}
