
@import "1-tools/normalize";

// fonts
@import "1-tools/fonts.scss";

 h1,h2,h3,h4,h5,h6,p,a,span, i{
   color: #474747;
 }

 @import "./2-vars/variables.scss";

 @import "./3-modules/styleMax319.scss";
 @import "./3-modules/styleMin320.scss";
 @import "./3-modules/styleMax500.scss";
 @import "./3-modules/styleMin768.scss";
 @import "./3-modules/styleMin1024.scss";
 @import "./3-modules/styleMax1199.scss";
 @import "./3-modules/styleMin1200.scss";

// validation
.error-message {
  color: $error-color;
  padding: 10px 0 0 0 !important;
  text-align: left !important;
}

.send-status-error {
  color: $error-color;
}

.send-status-successful {
  color: $successful-color;
}

.error {
  border-color: $error-color;
}

.send-status-wrapper {
  display: flex;
  justify-content: center;

  &_error {
    img {
      margin-right: 10px;
    }
    span {
      display: inline-flex !important;
      padding: 0 !important;
    }

    display: flex;
    align-items: center;
  }
  &_success {
    img {
      margin-right: 10px;
    }
    span {
      display: inline-flex !important;
      padding: 0 !important;
    }

    display: flex;
    align-items: center;
  }
}

.landing_head {
  text-transform: uppercase;
  color: #ff8331;
  line-height: 1.43;
  letter-spacing: -0.5px;
  font-stretch: normal;
}

.navbar-brand span {
  font-weight: bold;
}

.appStore {
  background-image: url(../img/app-store.png);
}

.googlePlay {
  background-image: url(../img/google-play.png);
}

.form-control {
  border: solid 1px #d7d7d7;
}

.form-control:focus {
  border: solid 1px #474747;
}

.txt_ref {
  color: #ff8331;
}
.txt_ref:hover {
  color: #ff8331;
  text-decoration-color: #ff8331;
}












 //

