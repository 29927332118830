@media only screen and (min-width: 768px){
  body{
    font-size: 16px;
  }
  h2{
    font-size: 22px;
  }
  .wrapper-record{
    margin-bottom: 0;
  }

  .mobile-btn-send{
    width: 159px;
  	height: 49px;
  }

}
