@media only screen and (min-width: 1024px){
  .about p{
  }
  .wrapper-record{
    padding-top: 4%;
    padding-bottom: 5%;
    margin-bottom: 0;
  }

  .record span{
    padding-top: 2%;
    padding-bottom: 2%;
  }
  .record span a{
    width: 35%;
  }
  .record-title{
    padding-top: 0;
  }
  .wrapper-mobile-contact{
    padding-top: 3%;
    padding-bottom: 5%;
  }
  .tablet-form{
    float: left;
    width: 100%;
  }
  .tablet-form-first{
    padding-right: 1%;
    float: left;
    width: 50%;
  }
  .tablet-form-second{
    float: left;
    padding-left: 1%;
    width: 50%;
  }
  .mobile-contac{
    label{
    padding-bottom: 2%;
    }
    
    p{
      padding-bottom: 5%;
    }
  }

  .mobile-footer{
    i{
      display: none;
    }
    a{
      border-bottom: none;
      float: right;
      text-align: center;
      width: 8%;
      &:hover span{
        color: #E6982E;
        cursor: pointer;
      }
    }
  }

}
