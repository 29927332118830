@media only screen and (min-width: 1200px){
  h2{
    font-size: 32px;
  }
  h5{
    font-size: 20px;
  }
  
  .record span{
    text-align: center;
    a{
      width: 35%;
    }
  }
  .mobile-footer h5{
    font-size: 16px;
    padding: 2% 4%;
  }
  .mobile-footer a{
    padding: 2% 0.5%;
  }
  .main_img {
    height: 50px;
    img {
      margin-top: -27%;
    }
  }
  .wrapper-record{
    p{
      line-height: 1.88;
    }
  }
}
